import IntervalTree from '@flatten-js/interval-tree';
import { createSelector } from 'reselect';

import { AsyncState, AsyncStateError, ShimState } from '../../../types';
import { isCompleteSelector, isFailedSelector, isPendingSelector } from '../../../utils';
import {
    CurrentDrillholeState,
    DataFileGroupResponse,
    DeletedFileState,
    isGroupUser as isUserGroup,
    DownloadedFileType,
    Drillhole,
    DrillholeItemsState,
    DrillholeState,
    FileGroupState,
    FileObject,
    FilesForGroup,
    FilesForGroupState,
    HeaderFileGroupResponse,
    LabCertificateInfo,
    RowReference,
    RowReferencesListPointers,
    Sample,
    ModifiedRowsInfo,
    ValidationErrorsState,
} from '../types';

import { INITIAL_CURRENT_DRILLHOLE_STATE, INITIAL_VALIDATION_ERRORS_STATE } from './reducer';

const drillholeState = (state: Partial<ShimState>): DrillholeState => {
    const { drillhole: dhDrillHole = {} as DrillholeState } = state || {};
    return dhDrillHole;
};
const drillholeError = createSelector(drillholeState, ({ error }): AsyncStateError => error ?? {});

// =================================================================
const exportCollarState = createSelector(
    drillholeState,
    ({
        exportCollarsState: dhExportCollarsState = { status: 'pending', error: null },
    }: DrillholeState): AsyncState => dhExportCollarsState,
);

const customExportState = createSelector(
    drillholeState,
    ({
        customExportState: dhCustomExportState = { status: 'pending', error: null },
    }: DrillholeState): AsyncState => dhCustomExportState,
);
// =================================================================

const validationErrorsState = createSelector(
    drillholeState,
    ({
        validationErrorsState = INITIAL_VALIDATION_ERRORS_STATE,
    }: DrillholeState): ValidationErrorsState => validationErrorsState,
);

const addDrillHoleState = createSelector(
    drillholeState,
    ({ addDrillHoleState: dhAddDrillHoleState }): AsyncState => dhAddDrillHoleState,
);
const addDrillHoleError = createSelector(
    addDrillHoleState,
    ({ error }): AsyncStateError => error ?? {},
);
const drillholeCount = createSelector(
    drillholeState,
    ({ count = 0 }: DrillholeState): number => count,
);

const getSelectedDrillHoles = createSelector(
    drillholeState,
    ({ selectedDrillHoles = [] }: DrillholeState): Drillhole[] => selectedDrillHoles,
);
const drillholeItems = createSelector(
    drillholeState,
    ({ items = {} as any }: DrillholeState): DrillholeItemsState => items,
);

const drillholes = createSelector(
    drillholeItems,
    ({ collars = {} }: DrillholeItemsState): Record<string, Drillhole> => collars,
);
const isPending = isPendingSelector(drillholeState);

const drillhole = (id?: string) =>
    createSelector(
        drillholes,
        currentDrillhole,
        (dhDrillHoles: Record<string, Drillhole>, current: CurrentDrillholeState): Drillhole =>
            dhDrillHoles[id ?? current.id] || ({} as Drillhole),
    );

const drillholeLoaded = (id: string) =>
    createSelector(
        drillhole(id),
        (dhDrillHole: Drillhole): boolean => !!dhDrillHole && Object.keys(dhDrillHole).length > 0,
    );

const getDrillholeValue = (id: string, valueKey: string) =>
    createSelector(drillhole(id), (dhDrillHole: Drillhole): any =>
        dhDrillHole.values ? dhDrillHole.values[valueKey] : undefined,
    );
const getDrillholeUserIds = (id: string, drillHole: Drillhole | null = null) =>
    createSelector(drillhole(id), (dhDrillHole: Drillhole): Record<string, string> => {
        const drillHoleToOperateOn = drillHole ?? dhDrillHole;
        if (!drillHoleToOperateOn) {
            return {};
        }
        const { assignedUser } = drillHoleToOperateOn;
        if (!assignedUser) {
            return {};
        }
        if (isUserGroup(assignedUser)) {
            const groupUsers: Record<string, string> = {};
            Object.entries(assignedUser.groupUsers).forEach(([key, value]) => {
                groupUsers[key] = value.user;
            });
            return groupUsers;
        }
        return { [assignedUser.user]: assignedUser.user };
    });

const drillholesAsList = createSelector(
    drillholes,
    (dhDrillHoles: Record<string, Drillhole>): Drillhole[] => {
        if (!dhDrillHoles) return [];
        return Object.values(dhDrillHoles).map((val: Drillhole) => val);
    },
);

const currentDrillhole = createSelector(
    drillholeState,
    ({ current = INITIAL_CURRENT_DRILLHOLE_STATE }: DrillholeState): CurrentDrillholeState =>
        current,
);

const rows = createSelector(
    drillholeState,
    ({ rows: dhRows = {} as Record<string, any> }: DrillholeState): Record<string, any> => dhRows,
);

const columns = createSelector(
    drillholeState,
    ({ columns: dhColumns = [] as any[] }: DrillholeState): any[] => dhColumns,
);

const excludedFillDownColumns = createSelector(
    drillholeState,
    ({
        excludedFillDownColumns: dhExcludedFillDownColumns = [] as string[],
    }: DrillholeState): string[] => dhExcludedFillDownColumns,
);

const rowReferences = createSelector(
    drillholeState,
    ({
        rowReferences: dhRowReferences = {} as Record<string, RowReference>,
    }: DrillholeState): Record<string, RowReference> => dhRowReferences,
);

const rowReferenceIntervalTree = createSelector(
    drillholeState,
    ({
        rowReferencesIntervalTree: dhRowReferenceIntervalTree,
    }: DrillholeState): IntervalTree | null => dhRowReferenceIntervalTree,
);

const rowReferencesListPointers = createSelector(
    drillholeState,
    ({
        rowReferencesListPointers: dhRowReferencesPointers = {} as RowReferencesListPointers,
    }: DrillholeState): RowReferencesListPointers => dhRowReferencesPointers,
);

const maxRowSpanCount = createSelector(
    drillholeState,
    ({ maxRowSpanCount: dhMaxRowSpanCount = 1 }: DrillholeState): number => dhMaxRowSpanCount,
);

const samples = createSelector(
    drillholeState,
    ({ samples: dhSamples }: DrillholeState): Record<string, Sample> => dhSamples,
);

const rowReferencesLoadState = createSelector(
    drillholeState,
    ({ rowReferencesLoadState: dhRowReferencesLoadState }: DrillholeState): AsyncState =>
        dhRowReferencesLoadState,
);

const modifiedRowReferencesState = createSelector(
    drillholeState,
    ({ modifiedRowReferencesState: dhmodifiedRowReferenceState }): AsyncState =>
        dhmodifiedRowReferenceState,
);

const modifiedRowReferencesError = createSelector(
    modifiedRowReferencesState,
    ({ error }): AsyncStateError => error ?? {},
);

//= ===============================================================
const exportCollarsState = createSelector(
    drillholeState,
    ({ exportCollarsState: dhExportCollarsState }): AsyncState => dhExportCollarsState,
);
const exportCollarsError = createSelector(
    exportCollarsState,
    ({ error }): AsyncStateError => error ?? {},
);
//= ===========================================================================

const cloneDrillHoleState = createSelector(
    drillholeState,
    ({ cloneDrillHoleState: dhCloneDrillHoleState }): AsyncState => dhCloneDrillHoleState,
);

const cloneDrillHoleError = createSelector(
    cloneDrillHoleState,
    ({ error }): AsyncStateError => error ?? {},
);

const saveDrillHoleState = createSelector(
    drillholeState,
    ({ saveDrillHoleState: dhSaveDrillHoleState }): AsyncState => dhSaveDrillHoleState,
);

const deleteDrillHoleState = createSelector(
    drillholeState,
    ({ deleteDrillHoleState: dhDeleteDrillHoleState }): AsyncState => dhDeleteDrillHoleState,
);
const saveDrillHoleError = createSelector(
    saveDrillHoleState,
    ({ error }): AsyncStateError => error ?? {},
);

const deleteDrillHoleError = createSelector(
    deleteDrillHoleState,
    ({ error }): AsyncStateError => error ?? {},
);

const modifiedRows = createSelector(
    drillholeState,
    ({
        modifiedRows: dhModifiedRows = {
            reconstructAllRows: false,
            isSorted: false,
            rows: [],
            redrawRows: [],
            isSequenceChanged: false,
        },
    }: DrillholeState): ModifiedRowsInfo => dhModifiedRows,
);

const fileGroupState = createSelector(
    drillholeState,
    ({ fileGroup: dhFileGroupState }): FileGroupState => dhFileGroupState,
);

const fileGroup = createSelector(
    drillholeState,
    ({ fileGroup: dhFileGroupState }): DataFileGroupResponse | HeaderFileGroupResponse =>
        dhFileGroupState.fileGroup,
);

const emptyFileGroups = createSelector(
    drillholeState,
    ({ emptyFileGroups: dhEmptyFileGroups }): Record<string, string[]> => dhEmptyFileGroups,
);

const uploadedFiles = createSelector(
    drillholeState,
    ({ uploadedFiles: dhUploadedFiles = [] }: any): any => dhUploadedFiles,
);

const deletedFileState = createSelector(
    drillholeState,
    ({ deletedFile: dhDeletedFileState }): DeletedFileState => dhDeletedFileState,
);

const deletedFile = createSelector(
    drillholeState,
    ({ deletedFile: dhDeletedFileState }): FileObject => dhDeletedFileState.deletedFile,
);

const downloadedFile = createSelector(
    drillholeState,
    ({ downloadedFile: dhDownloadedFile }): DownloadedFileType | null => dhDownloadedFile,
);

const filesForGroupState = createSelector(
    drillholeState,
    ({ filesForGroup: dhFilesForGroupState }): FilesForGroupState => dhFilesForGroupState,
);

const loadLabCertificateInfo = createSelector(
    drillholeState,
    ({
        labCertificateInfoState: dhLoadLabCertificateInfo,
    }): Record<string, LabCertificateInfo> | null => dhLoadLabCertificateInfo?.labCertInfo ?? null,
);

// selector for dumpTableData
const dumpTableDataState = createSelector(
    drillholeState,
    ({ dumpTableData: dhDumpTableData }): any => dhDumpTableData,
);

const filesForGroup = createSelector(
    drillholeState,
    ({ filesForGroup: dhFilesForGroupState }): Record<string, FilesForGroup> =>
        dhFilesForGroupState.filesForGroup,
);

const filesForGroupLengths = createSelector(
    drillholeState,
    ({ filesForGroupLengths: dhFilesForGroupLengths = {} as Record<string, number> }) =>
        dhFilesForGroupLengths,
);

const unassignUserFromDrillholeState = createSelector(
    drillholeState,
    ({ unassignUserState }) => unassignUserState,
);

const unassignUserFromDrillholeStateError = createSelector(
    unassignUserFromDrillholeState,
    ({ error }): AsyncStateError => error ?? {},
);

const isSelectedAll = createSelector(
    drillholeState,
    ({ isSelectedAll = false }): boolean => isSelectedAll,
);

const isClearedAll = createSelector(
    drillholeState,
    ({ isClearedAll = false }): boolean => isClearedAll,
);

const unSelectedCollars = createSelector(
    drillholeState,
    ({ unSelectedCollars: dhUnSelectedCollars }): Record<string, Drillhole> => dhUnSelectedCollars,
);

const lithologyTemporaryRows = createSelector(
    drillholeState,
    ({ lithologyTemporaryRows: dhlithologyTemporaryRows }): string[] => dhlithologyTemporaryRows,
);

const externalFilter = createSelector(
    drillholeState,
    ({ externalFilter: dhExternalFilter }): string => dhExternalFilter,
);

const filterApplied = createSelector(
    drillholeState,
    ({ filterApplied: dhFilterApplied }): boolean => dhFilterApplied,
);

const collarSearchTerm = createSelector(
    drillholeState,
    ({ searchTerm: dhSearch = '' }): string => dhSearch,
);

export const selectors = {
    drillholeState,
    drillholeError,
    drillholeCount,
    drillholes,
    isPending,
    isFailed: isFailedSelector(drillholeState),
    isComplete: isCompleteSelector(drillholeState),
    drillhole,
    getDrillholeValue,
    getDrillholeUserIds,
    drillholeLoaded,
    drillholesAsList,
    currentDrillhole,
    addDrillHoleState,
    deleteDrillHoleState,
    modifiedRowReferencesState,
    isCurrentDrillholePending: isPendingSelector(currentDrillhole),
    isCurrentDrillholeFailed: isFailedSelector(currentDrillhole),
    isCurrentDrillholeComplete: isCompleteSelector(currentDrillhole),
    isRowReferencesPending: isPendingSelector(rowReferencesLoadState),
    isRowReferencesComplete: isCompleteSelector(rowReferencesLoadState),
    isRowReferencesFailed: isFailedSelector(rowReferencesLoadState),
    isModifiedRowReferencesPending: isPendingSelector(modifiedRowReferencesState),
    isModifiedRowReferencesFailed: isFailedSelector(modifiedRowReferencesState),
    isModifiedRowReferencesCompleted: isCompleteSelector(modifiedRowReferencesState),
    isAddDrillHoleCompleted: isCompleteSelector(addDrillHoleState),
    isAddDrillHoleFailed: isFailedSelector(addDrillHoleState),
    isCloneDrillHoleCompleted: isCompleteSelector(cloneDrillHoleState),
    isCloneDrillHoleFailed: isFailedSelector(cloneDrillHoleState),
    isSaveDrillHoleFailed: isFailedSelector(saveDrillHoleState),
    isSaveDrillHoleCompleted: isCompleteSelector(saveDrillHoleState),
    isSaveDrillHolePending: isPendingSelector(saveDrillHoleState),
    isDeleteDrillHoleFailed: isFailedSelector(deleteDrillHoleState),
    isDeleteDrillHoleCompleted: isCompleteSelector(deleteDrillHoleState),
    isGetFilesForGroupFailed: isFailedSelector(filesForGroupState),
    isFileGroupFailed: isFailedSelector(fileGroupState),
    isDeleteFileFailed: isFailedSelector(deletedFileState),
    rows,
    dumpTableDataState,
    rowReferences,
    rowReferencesListPointers,
    rowReferenceIntervalTree,
    maxRowSpanCount,
    samples,
    modifiedRows,
    columns,
    excludedFillDownColumns,
    getSelectedDrillHoles,
    fileGroup,
    uploadedFiles,
    deletedFile,
    downloadedFile,
    filesForGroup,
    filesForGroupLengths,
    addDrillHoleError,
    cloneDrillHoleError,
    cloneDrillHoleState,
    saveDrillHoleError,
    saveDrillHoleState,
    deleteDrillHoleError,
    modifiedRowReferencesError,
    emptyFileGroups,
    loadLabCertificateInfo,
    unassignUserFromDrillholeState,
    unassignUserFromDrillholeStateError,
    exportCollarsError,
    exportCollarsState,
    isSelectedAll,
    isClearedAll,
    unSelectedCollars,
    exportCollarState,
    customExportState,
    isLoadCollarsPending: isPendingSelector(drillholeItems),
    isLoadCollarsCompleted: isCompleteSelector(drillholeItems),
    lithologyTemporaryRows,
    externalFilter,
    filterApplied,
    collarSearchTerm,
    validationErrorsState,
};
